import { newProduct } from './newProduct'

export const newCatalog = {
  name: 'Новый Каталог',
  showProducts: false,
  products: [newProduct],
  main: {
    revitCategories: '',
    categories: '',
    manufacturer: '',
    description: '',
  },
}
