<template>
  <div class="manufacturer-list">
    <v-progress-linear v-if="isLoading" indeterminate color="primary" />
    <div class="header catalog-blocks">
      <h3>Список производителей</h3>
      <div class="button-container">
        <div>
          <button class="import_button" @click="handleFileUpload">
            <span v-html="svgIconImportButton" />
            <span>IMPORT EXCEL</span>
          </button>
          <input
            ref="fileInput"
            type="file"
            style="display: none"
            accept=".xlsx,.xls"
            @change="importExcel"
          />
        </div>
        <v-btn class="add-catalog-button" depressed @click="addCatalog">
          <div class="icon-container" v-html="svgIconPlusCatalog" />
          <span>каталог</span>
        </v-btn>
        <!-- сортировка -->
        <v-btn
          class="custom-btn"
          text
          @click="toggleSortMenu"
          v-html="svgIconSort"
        />
        <div v-if="showSortMenu" class="sort-menu">
          <v-btn text @click="sort('date')">Сортировать по дате</v-btn>
          <v-btn text @click="sort('alphabet')">Сортировать по алфавиту</v-btn>
        </div>

        <!-- поиск -->
        <v-btn
          class="custom-btn"
          text
          @click="toggleSearchInput"
          v-html="svgIconSearch"
        />
        <input
          v-if="showSearchInput"
          v-model="searchText"
          class="search-catalogs-name"
          type="text"
          placeholder="Поиск..."
        />
      </div>
    </div>
    <table class="catalog-table">
      <thead>
        <tr class="catalog-blocks">
          <th>Наименование</th>
          <th>Производитель</th>
          <th>Дата создания</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="catalog-list">
        <tr
          v-for="catalog in filteredCatalogs"
          :key="catalog.id"
          class="catalog-blocks catalog"
        >
          <td>
            <router-link
              :to="{
                name: 'Catalog',
                params: {
                  catalog: catalog,
                  catalogs: catalogList,
                  id: catalog.id,
                },
              }"
            >
              {{ catalog.name }}
            </router-link>
          </td>
          <td class="manufacturer-name">
            {{ catalog.main.manufacturer?.name ?? catalog.main.manufacturer }}
          </td>
          <td class="manufacturer-name">{{ formatDate(catalog.date) }}</td>
          <td>
            <v-icon @click="removeCatalog(catalog.id)"> mdi-delete </v-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { newCatalog } from './data/newCatalog'

export default {
  data() {
    return {
      isLoading: false,
      showSearchInput: false,
      searchText: '',
      showSortMenu: false,
      sortOrder: 1,
      svgIconPlusCatalog: `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M11.8822 8.66667H9.2155V11.3333H7.88216V8.66667H5.21549V7.33333H7.88216V4.66667H9.2155V7.33333H11.8822M13.2155 2H3.88216C3.14216 2 2.54883 2.59333 2.54883 3.33333V12.6667C2.54883 13.0203 2.6893 13.3594 2.93935 13.6095C3.1894 13.8595 3.52854 14 3.88216 14H13.2155C13.5691 14 13.9083 13.8595 14.1583 13.6095C14.4084 13.3594 14.5488 13.0203 14.5488 12.6667V3.33333C14.5488 2.59333 13.9488 2 13.2155 2Z"
                  fill="white"/>
            </svg>`,
      svgIconSearch: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z"
                fill="#192724" fill-opacity="0.45"/>
          </svg>`,
      svgIconSort: `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.9994 14V21.88C16.0394 22.18 15.9394 22.5 15.7094 22.71C15.3194 23.1 14.6894 23.1 14.2994 22.71L12.2894 20.7C12.0594 20.47 11.9594 20.16 11.9994 19.87V14H11.9694L6.20937 6.62C5.86937 6.19 5.94937 5.56 6.37937 5.22C6.56937 5.08 6.77937 5 6.99937 5H20.9994C21.2194 5 21.4294 5.08 21.6194 5.22C22.0494 5.56 22.1294 6.19 21.7894 6.62L16.0294 14H15.9994Z"
                fill="#192724" fill-opacity="0.45"/>
          </svg>`,
      svgIconImportButton: `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.88281 13.3333H13.2161V12H3.88281M13.2161 6H10.5495V2H6.54948V6H3.88281L8.54948 10.6667L13.2161 6Z" fill="#192724" fill-opacity="0.65"/>
                    </svg>
                    `,
    }
  },
  computed: {
    ...mapState('catalogs', ['catalogList']),
    filteredCatalogs() {
      if (!this.searchText) {
        return this.catalogList
      }
      return this.catalogList.filter((catalog) =>
        catalog.name.toLowerCase().includes(this.searchText.toLowerCase())
      )
    },
  },
  async mounted() {
    this.isLoading = true
    await this.fetchCatalogList()
    this.isLoading = false
  },
  methods: {
    ...mapActions('catalogs', [
      'removeCatalogById',
      'fetchCatalogList',
      'importExcelParse',
    ]),
    handleFileUpload() {
      this.$refs.fileInput.click()
    },
    async importExcel(event) {
      const file = event.target.files[0]
      if (file) {
        const formData = new FormData()
        formData.append('excel_file', file)
        this.isLoading = true
        await this.importExcelParse(formData)
        await this.fetchCatalogList()
        this.isLoading = false
      }
    },
    async removeCatalog(id) {
      if (!confirm('Удалить каталог?')) return
      const error = await this.removeCatalogById(id)
      if (!error) {
        this.fetchCatalogList()
      }
    },
    addCatalog() {
      const catalog = JSON.parse(JSON.stringify(newCatalog))
      catalog.id = this.getUniqueId(this.catalogList)
      catalog.date = new Date().toISOString()
      this.catalogList.push(catalog)
    },
    getUniqueId(arrayAttack) {
      let maxId = 0
      for (const item of arrayAttack) {
        if (item.id > maxId) {
          maxId = item.id
        }
      }
      return parseInt(maxId) + 1
    },
    toggleSearchInput() {
      this.showSearchInput = !this.showSearchInput
    },
    search() {
      if (this.searchInput.trim() === '') {
        this.filteredCatalogs = this.catalogList
      } else {
        this.filteredCatalogs = this.catalogList.filter((catalog) =>
          catalog.name.toLowerCase().includes(this.searchInput.toLowerCase())
        )
      }
    },
    toggleSortMenu() {
      this.showSortMenu = !this.showSortMenu
    },
    sort(type) {
      this.sortOrder = -this.sortOrder
      if (type === 'date') {
        this.filteredCatalogs.sort(
          (a, b) => this.sortOrder * (new Date(a.date) - new Date(b.date))
        )
      } else if (type === 'alphabet') {
        this.filteredCatalogs.sort(
          (a, b) => this.sortOrder * a.name.localeCompare(b.name)
        )
      }
      this.toggleSortMenu()
    },
    formatDate(isoDateString) {
      const date = new Date(isoDateString)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')

      return `${day}-${month}-${year}`
    },
  },
}
</script>

<style scoped>
.import_button span {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  color: rgba(25, 39, 36, 0.75);
}

.import_button {
  width: 133px !important;
  margin-right: 33px;
}

.import_button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 86.9px;
  height: 28px;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 0px 12.45px 0px 8.45px;
}

.sort-menu {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.sort-menu v-btn {
  display: block;
  width: 100%;
  text-align: left;
  padding: 12px 16px;
  border: none;
  background: none;
  cursor: pointer;
}

.sort-menu v-btn:hover {
  background-color: #f1f1f1;
}

.search-catalogs-name {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 8px;
}

.manufacturer-name {
  color: black;
}

.custom-btn {
  height: 0px !important;
  min-width: 0px !important;
  padding: 0px !important;
  margin: 0 10px 0 10px;
}

.manufacturer-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  gap: 10px;
  width: 100%;
  background: #ffffff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.add-catalog-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 700;
  line-height: 14px;
  color: #ffffff !important;
  background: #4eaca1 !important;
  border-radius: 4px;
  width: 99.9px;
  gap: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0 10px 10px 0;
}

.add-catalog-button span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  margin-left: 5px;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}

.add-catalog-button .v-icon.theme--light.plus-box {
  width: 16px;
  height: 16px;
  position: relative;
  flex: none;
  order: 0;
  align-self: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.catalog-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.catalog-blocks {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 0 0 8px 10px;
  display: flex;
}

.catalog-blocks th,
.catalog-list td {
  padding: 10px 7px 7px;
  margin-right: 30px;
}

.catalog-list td {
  width: 150px;
}

.catalog-blocks th::after,
.catalog-list td::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
}

.catalog-table {
  width: 100%;
  border-collapse: collapse;
}

.catalog {
  color: #4eaca1;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.catalog-list a {
  text-decoration: none;
}

.button-container {
  display: flex;
  flex-direction: row-reverse;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: center;
}
</style>
